import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
    useHistory,
} from "react-router-dom";
import { List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import InnerRoutes from './InnerRoutes';
import { clearApiToken, decodeToken, getApiToken } from './toolympus/api/core';
// import { SchemaProvider } from './toolympus/hooks/useSchema';
import { AppWrapper, Sidebar, Menu, Routes, getRoutesToRender, AppContentSimple, DrawerPaperView } from './toolympus/components/frame/new';
import { DefaultUser, UserContext } from './toolympus/userContext/UserContext';
import { ExitToApp, Task } from '@mui/icons-material';
import { useLoginGuard } from './toolympus/hooks/auth/useLoginGuard';
import { Logo } from './components/Common';
import { ThemeSettings } from './theme';
import { ProvidersInternal } from './ProvidersInternal';
import { useDialogState } from './toolympus/components/primitives';
import { MyTasksCountBadge, TasksDrawer } from './toolympus/components/Tasks';


const DrawerPaperS = styled(DrawerPaperView)`
  & .MuiDrawer-paper {
    color: ${ThemeSettings.colors.background.normal};
    background-color: ${ThemeSettings.colors.text};
  }
  & .MuiListItem-root {
    border-left: 4px solid transparent;
    color: inherit;

    & .MuiSvgIcon-root {
      color: ${ThemeSettings.colors.background.normal};
    }
    
    &.Mui-selected {
      border-left: 4px solid ${ThemeSettings.colors.accent};
      & .MuiSvgIcon-root {
        color: ${ThemeSettings.colors.accent};
      }
    }
  }
`;

const AppRouter: React.FC = () => {
    const token = getApiToken();
    const history = useHistory();
    const [user, setUser] = useState<DefaultUser>(token ? decodeToken(token) as DefaultUser : { _id: '', roles: [], email: '' });

    const logout = () => {
        clearApiToken();
        history.push('/login');
    }

    useLoginGuard("/login", { isAuthenticated: !!user && user.roles.length > 0 });

    const { routes, menuItems } = getRoutesToRender(InnerRoutes, user);

    const tasksDrawer = useDialogState();

    const bottom = (
        <List>
            <ListItem button key='tasks' onClick={() => tasksDrawer.open()}>
                <ListItemIcon><MyTasksCountBadge color="secondary" apiPath="/api/tasks"><Task /></MyTasksCountBadge></ListItemIcon>
                <ListItemText primary="Задачи" />
            </ListItem>
            <ListItem button key='logout' onClick={logout}>
                <ListItemIcon><ExitToApp /></ListItemIcon>
                <ListItemText primary='Выход' />
            </ListItem>
        </List>
    )

    return (
        <UserContext.Provider value={{ user: user, setUser: setUser }}>
          <ProvidersInternal>

                <AppWrapper>
                    <Sidebar
                      top={<Logo />}
                      menu={<Menu menuItems={menuItems} />}
                      bottom={bottom}
                      open={true}
                      onClose={() => { }}
                      drawerPaperProps={{ shadow: true }}
                      drawerPaperComponent={DrawerPaperS}
                      />
                    <TasksDrawer
                      apiPath="/api/tasks"
                      isOpen={tasksDrawer.isOpen}
                      close={tasksDrawer.close}
                      title={<Typography variant="h6">Задачи</Typography>}
                      />
                    <AppContentSimple fit100Height>
                        <Routes routes={routes} notFoundRedirect="/home" />
                    </AppContentSimple>
                </AppWrapper>
              
          </ProvidersInternal>
        </UserContext.Provider>
    );
}

export default AppRouter;

