import React, { ComponentType } from 'react';
import { RenderElementProps } from 'slate-react';
import { useDocumentationPagesPlugin } from '../../../toolympus/components/Documentation';
import { DocumentationDoc } from '../../../toolympus/components/Documentation/useDocumentationPages';
import { PowerEditorBaseProps, PowerEditorBase, DocumentTitle } from '../../../toolympus/components/PowerDoc';
import { createWrappedPowerEditor } from '../../../toolympus/components/PowerDoc/PowerEditorSettingsContext';
import { ColumnsPlugin } from '../../../toolympus/components/PowerDoc/plugins/Columns';
import { useCustomFieldsViewerPlugin } from '../../../toolympus/components/PowerDoc/plugins/CustomFieldsBlock';
import { ExpanderPlugin } from '../../../toolympus/components/PowerDoc/plugins/Expander';
import { useMediaFilesPlugin } from '../../../toolympus/components/PowerDoc/plugins/MediaFiles';
import { TablesPlugin } from '../../../toolympus/components/PowerDoc/plugins/Tables';
import { TilesPlugin } from '../../../toolympus/components/PowerDoc/plugins/Tiles';
import { FormWrapper, FormHeader, LoadingIndicator, FormBody } from '../../../toolympus/components/primitives';
import { useLoadedData } from '../../../toolympus/hooks/useLoadedData';
import { DashboardBlock } from '../../../toolympus/components/Dashboard/DashboardBlock';

interface Props {
  
}

const CustomBlocks: Record<string, ComponentType<RenderElementProps>> = {
  dashboard: DashboardBlock,
}

const IclrcMPDocumentationViewerUnwrapped = (props: PowerEditorBaseProps) => {
  const mediaFilesPlugin = useMediaFilesPlugin();
  const pagesPlugin = useDocumentationPagesPlugin();
  const customBlocks = useCustomFieldsViewerPlugin(CustomBlocks);

  return (
      <PowerEditorBase
          {...props}
          plugins={[
              TilesPlugin,
              ColumnsPlugin,
              TablesPlugin,
              ExpanderPlugin,
              mediaFilesPlugin,
              pagesPlugin,
              customBlocks,
          ]}
          autoFocus={!props.viewMode}
          viewMode
          />
  );
}

const BiodevDocumentationViewer = createWrappedPowerEditor({ EditorComponent: IclrcMPDocumentationViewerUnwrapped });


export const HomePage = (props: Props) => {
  const data = useLoadedData<DocumentationDoc>("/api/documentation/slug/app_home", {
    _id: "",
    content: undefined,
    title: "",
  });

  return (
    <FormWrapper>
      <FormHeader>
        <DocumentTitle
          value={data.data.title}
          placeholder="..."
          update={() => {}}
          readOnly
          style={{ flex: "1 0 auto" }}
          />

        {data.isLoading && <LoadingIndicator sizeVariant="s" />}
      </FormHeader>

      <FormBody>
        <BiodevDocumentationViewer
          content={data.data.content}
          key={data.data._id}
          update={() => {}}
          />
      </FormBody>
    </FormWrapper>
  );
}
