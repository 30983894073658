import React, { ComponentType, memo, ReactNode } from 'react';
import { Buttons, SearchField, useListAutoexpander } from '../primitives';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { TreeItemsConfigWithManage } from './useManageTree';
import { WithLevel } from './treeHelpers';
import { TreeViewData } from './useTreeView';
import { CollapseButton, ItemRowWrapper, LevelMarker, TableHeader } from './TreeComponents';



interface ItemRowProps<T> {
  row: T;
  handleRowClick?: (row: T) => void;
  actions?: ComponentType<{ row: T }>;
  isCollapsible?: boolean;
  isCollapsed?: boolean;
  toggleCollapsed: (r: T) => void;
  config: TreeItemsConfigWithManage<T>;

  deHighlight?: boolean;
}

const ItemRow = <T,>(props: ItemRowProps<T>) => {
  const { row,
    isCollapsible,
    isCollapsed,
    toggleCollapsed,
    handleRowClick,
    config,
    deHighlight,
  } = props;

  const ActionsComponent = props.actions;

  return (
    <ItemRowWrapper key={row[config.idField] as any} deHighlight={deHighlight}>
      <TableCell>
        <Buttons>
          <LevelMarker level={(row as unknown as WithLevel).level} />
          <CollapseButton
            isCollapsible={isCollapsible}
            isCollapsed={isCollapsed}
            onClick={() => toggleCollapsed && toggleCollapsed(row)}
            />
            <span onClick={handleRowClick ? () => handleRowClick(row) : undefined}>
              {config.itemTitle(row)}
            </span>
        </Buttons>
      </TableCell>

      
      <TableCell>
        <Buttons gap="small">
          {ActionsComponent && <ActionsComponent row={row} />}
        </Buttons>
      </TableCell>
    </ItemRowWrapper>
  )
}

const ItemRowMemo = memo(ItemRow);

interface Props<T> {
  data: TreeViewData<T>;
  noSearch?: boolean;
  rowComponent?: React.ComponentType<ItemRowProps<T>>;
  rowActionsComponent?: ComponentType<{ row: T }>;
  titleLabel?: ReactNode;
  onSubmitSingle?: (row: T) => void;
  
  autoexpander?: {
    initialRows: number;
    increment: number;
  };
}

export const TreeListView = <T,>(props: Props<T>) => {
  const { data } = props;

  const RowComponent = props.rowComponent || ItemRowMemo;

  const autoexpander = useListAutoexpander(props.autoexpander ? data.data : [], props.autoexpander?.initialRows || 20, props.autoexpander?.increment || 20);

  const visibledata = props.autoexpander ? autoexpander.visibleItems : data.data;

  return (<>
      {!props.noSearch &&
        <SearchField
          filter={data.filter.filter}
          setFilter={data.filter.setFilter}
          noButton
          fullWidth
          autoFocus
          doSearch={() => {
            if(data.matchingData.length === 1 && props.onSubmitSingle) {
              props.onSubmitSingle(data.matchingData[0]);
            }
          }}
          />}
      <TableContainer>
        <Table size="small">
            <TableHeader>
              <TableRow>
                <TableCell>
                  <Buttons>
                    <CollapseButton
                      isCollapsible={false}
                      isCollapsed={false}
                      onClick={() => {}}
                      />
                      {props.titleLabel}
                  </Buttons>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHeader>
            <TableBody>
              {visibledata.filter(row => !data.collapser.isHidden(row)).map((row) => (
                <RowComponent
                  key={row[data.config.idField] as any}
                  row={row}
                  actions={props.rowActionsComponent as any}
                  isCollapsible={data.collapser.isCollapsible(row)}
                  isCollapsed={data.collapser.isCollapsed(row)}
                  toggleCollapsed={data.collapser.toggleCollapsed as any}
                  config={data.config as any}
                  deHighlight={!!data.filter.filter && !data.filter.filterData([row]).length}
                  />
                ))}
            </TableBody>
        </Table>
        {props.autoexpander && autoexpander.anchor}
      </TableContainer>
  </>);
}
