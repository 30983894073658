import { useMemo } from "react";
import { TextFilter, useTextFilter } from "../schemed/Filtering/useTextFilter";
import { CollapseTreeData, ConstantlyEmpty, TreeItemsConfigWithManage, useCollapseTree } from "./useManageTree";
import { assembleTree, disassembleTree, pathToRoot, WithLevel } from "./treeHelpers";

export interface TreeViewData<T> {
  data: (T & WithLevel)[];
  matchingData: (T & WithLevel)[];
  filter: TextFilter<T>;
  collapser: CollapseTreeData<T>;
  config: Omit<TreeItemsConfigWithManage<T>, "newItemDefault">;
}

export const useTreeView = <T,>(cfg: Omit<TreeItemsConfigWithManage<T>, "newItemDefault">, data: T[]): TreeViewData<T> => {
  const filter = useTextFilter<T>(cfg.textFilterBy || ConstantlyEmpty);


  const [treeData,tree] = useMemo(
    () => {
      const tree = assembleTree(cfg, data);
      return [disassembleTree(cfg, tree), tree];
    },
    [data, cfg]);

  const [filtered,matching] = useMemo(
    () => {
      if(filter.filter.length === 0) {
        return [treeData,treeData];
      }

      const matchingItems = filter.filterData(treeData);
      const codesWithParents = matchingItems.map(item => pathToRoot(cfg, tree, item[cfg.idField])).reduce((r,x) => { r.push(...x); return r; }, []);
      const fullCodesSet = new Set(codesWithParents);
      return [
        treeData.filter(item => fullCodesSet.has(item[cfg.idField])),
        matchingItems,
      ];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [treeData, filter.filter]
  )

  const collapser = useCollapseTree(cfg, tree);

  return {
    data: filtered,
    matchingData: matching as (T & WithLevel)[],

    filter,

    collapser,
    
    config: cfg,
  }
}
