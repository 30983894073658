import { useSingleSchema } from "../../toolympus/hooks/useSchema";
import { useSmartList } from "../../toolympus/hooks/useSmartList";
import { useBrowserStoredValue } from "../../toolympus/hooks/useBrowserStoredValue";

export interface Document {
  _id: number;
  title: string;
  created_at?: string;
  is_published?: boolean;
  published_at?: string;
  published_by?: string;
  actualized_at?: string;
  actualized_by?: string;
  description?: any;
  content?: any;

  source_title?: string;

  moderation_status?: string;
}


export const DocumentApiPath = "/api/document";

export const useDocumentSchema = () => {
  const { schema } = useSingleSchema(`${DocumentApiPath}/uiconfig`);
  return schema;
}

const DocumentTextFilter = (r: Document) => `${r.title} ${r._id}`;

export const useDocumentsList = () => {
  const schema = useDocumentSchema();

  const assignedFilter = useBrowserStoredValue("me", undefined, "__mp_documents_my_all");

  const data = useSmartList(DocumentApiPath, {
    sorting: {
      disallowedFields: ["description"],
    },
    extraParams: {
      assigned: assignedFilter.value === "me" ? "me" : undefined,
    },
    viewDefault: "drafts",
    lsKeysPrefix: "__iclrcmp_doclist",
    newRecordDefault: { title: "" },
    schema,
    textFilterFn: DocumentTextFilter,
  });

  return {
    ...data,
    assignedFilter,
  }
  
}

export type DocumentsListData = ReturnType<typeof useDocumentsList>;
