import { useCallback, useMemo, useState } from "react";
import { useLoadedData } from "../../hooks/useLoadedData"
import { useExecuteTaskAction } from "./useExecuteTaskAction";
import { ActionDescription, ActionsConfig, Task } from "./types";
import { useBrowserStoredValue } from "../../hooks/useBrowserStoredValue";
import { useThrottledState } from "../primitives";
import { FieldType, createSelectSchema } from "../../hooks/useSchema";

const Limit = {
  start: 50,
  step: 50,
}

interface Config {
  dontLoad?: boolean;
}

const TypesFilterLSKey = "_tl_tasks_types";

export const useTaskList = (apiPath: string, cfg?: Config) => {
  const { value: showUnassigned, update: setShowUnassigned } = useBrowserStoredValue("f", undefined, "__tl_tasks_show_unassigned");
  const [limit, setLimit] = useState<number>(Limit.start);
  const [search, setSearch] = useState<string>("");
  const [searchThrottled,setSearchThrottled] = useThrottledState<string>("", 200);
  const { value: sort, update: setSort} = useBrowserStoredValue("", undefined, "_tl_tasks_sort");

  const [typesFilter,setTypesFilter] = useState<string[]>((localStorage.getItem(TypesFilterLSKey) || "").split(",").map(s => s.trim()).filter(x => !!x));
  
  const paramsNoLimit = [
    showUnassigned === "t" ? "unassigned=include" : "",
    sort ? `order-by=${sort}` : "",
    searchThrottled ? `search=${searchThrottled}` : "",
    typesFilter && typesFilter.length ? `task_types=${typesFilter.join(",")}` : ""
  ].filter(x => !!x).join("&");
  const tasks = useLoadedData<Task[]>(`${apiPath}?${paramsNoLimit}&limit=${limit}`, [], !cfg?.dontLoad);
  const tasksCount = useLoadedData<{ total_records: number }>(`${apiPath}/count?${paramsNoLimit}`, { total_records: 0 }, !cfg?.dontLoad);

  const reload = () => {
    tasks.reload();
    tasksCount.reload();
  }

  const actionsConfig = useLoadedData<ActionsConfig>(`${apiPath}/actions`, { descriptions: [], actions_by_task_type: {}, "task-types": [] });

  const [actionsByType, taskTypesAll, taskTypeFilterSchema] = useMemo(() => {
    const { descriptions, actions_by_task_type } = actionsConfig.data;
    const actionsByType = Object.entries(actions_by_task_type || {}).reduce<Record<string, ActionDescription[]>>((r, [k,v]) => {
      const configs = v.map(actionType => descriptions.find(a => a.action === actionType)).filter(a => !!a) as ActionDescription[];
      r[k] = configs;
      return r;
    }, {});

    const taskTypeFilterSchema = createSelectSchema(
      actionsConfig.data["task-types"].map(t => ({ value: t, label: t })),
      { label_id: "tasks.types_filter" });
    taskTypeFilterSchema.type = FieldType.multiselect;

    return [
      actionsByType,
      actionsConfig.data["task-types"],
      taskTypeFilterSchema,
    ]
  }, [actionsConfig.data]);

  const onActionExecuted = (task: Task, action: string, result: any) => {
    tasks.setData(d => d.map(t => t.task_id === task.task_id && t.task_type === task.task_type
      ? ({ ...t, is_show_result: true, result_description: result.description })
      : t
    ));
  }
  
  const removeExecuted = useCallback((task: Task) => {
    tasks.setData(d => d.filter(t => !(t.task_id === task.task_id && t.task_type === task.task_type && t.is_show_result)));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const executeAction = useExecuteTaskAction(apiPath, onActionExecuted);

  const canShowMore = limit < tasksCount.data.total_records;

  const showMore = () => {
    if(canShowMore) {
      setLimit(x => x+Limit.step);
    }
  }


  return {
    ...tasks,
    reload,
    isShowUnassigned: showUnassigned === "t",
    setIsShowUnassigned: (v: boolean) => setShowUnassigned(v ? "t" : "f"),
    actions: actionsByType,
    executeAction,
    removeExecuted,
    tasksCount: tasksCount.data.total_records,
    search,
    setSearch: (v: string) => {
      setSearch(v);
      setSearchThrottled(v);
    },
    sort,
    setSort,
    typesFilter,
    setTypesFilter: (x: string[]) => {
      localStorage.setItem(TypesFilterLSKey, x.join(","));
      setTypesFilter(x);
    },
    taskTypesAll,
    taskTypeFilterSchema,

    canShowMore,
    showMore,
  }
}

export type TaskListData = ReturnType<typeof useTaskList>;
