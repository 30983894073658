import React from 'react';
import { Form, TabPanel, TabsHeader, useTabsState } from '../../../toolympus/components/primitives';
import { ModerationSettings } from './ModerationSettings';

interface Props {
  
}

export const SettingsPage = (props: Props) => {
  const tabs = useTabsState([
    ["moderation", "Модерация"]
  ], "moderation", "tab", "__mp_settings_tab");

  return (
    <Form noTitle>
      <TabsHeader state={tabs} noMargin />

      <TabPanel state={tabs.forTab("moderation")}>
        <ModerationSettings />
      </TabPanel>
    </Form>
  );
}
