import React from "react";
import { DescriptionOutlined, BookOutlined, HomeOutlined, InfoOutlined, Settings, AssignmentOutlined, SettingsOutlined } from "@mui/icons-material";

import { MenuRoute } from "./toolympus/components/frame";
import { SystemSubmenu } from "./toolympus/components/Contests/SystemSubmenu";
import { MpCmsEditor } from "./components/Administration/MpEditor";
import { ConfigurableFormsRoutes } from "./toolympus/components/ConfigurableForms";
import { AllDictionariesPage } from "./components/Administration/AllDictionariesPage";
import { HomePage, Documentation } from "./components/Administration/Documentation";
import { DocumentsList, DocumentEditForm } from "./components/Documents";
import { SettingsPage } from "./components/Administration/Settings";

const InnerRoutes: MenuRoute[] = [
   
    { 
      path: '/home',
      title: 'Главная',
      component: HomePage,
      icon: <HomeOutlined />,
    },

      
    {
      hidden: true,
      path: "/document/:id",
      title: "Документы",
      component: DocumentEditForm,
    },
    {
      path: "/document",
      title: "Документы",
      component: DocumentsList,
      icon: <DescriptionOutlined />,
      alsoActivateForPath: p => p.startsWith("/document/"),
    },
    
    { 
      path: '/dictionaries',
      title: 'Справочники',
      icon: <BookOutlined />,
      component: AllDictionariesPage,
    },

    { 
        path: '/system',
        title: 'Конфигурация',
        icon: <Settings />,
        component: () => (
            <SystemSubmenu
              emails={{ apiPath: "/emails" }}
              users={{ allowCreateUsers: true, allowInvites: true, allowPaswordReset: true, allowPaswordChange: true, twoFactorAuthEnabled: true }}
              messages={{ apiPath: "/api/localization/messages" }}
              cms={{ apiPath: "/api/cms", includeMediaLib: true, EditorComponent: MpCmsEditor }}
              queryConsole={{}}
              apiTokens={{ apiPath: "/api/apitoken" }}
              extraItems={[
                {
                  key: "forms",
                  label: "Формы",
                  icon: <AssignmentOutlined />,
                  items: [{
                    key: "forms",
                    label: "Формы",
                    component: ConfigurableFormsRoutes,
                    componentProps: {
                      apiPath: "/api/form/config",
                      localization: {
                        defaultLocale: "ru",
                        locales: [],
                      }
                    }
                  }],
                },
                {
                  key: "settings",
                  label: "Настройки",
                  icon: <SettingsOutlined />,
                  items: [{ key: "settings", label: "", component: SettingsPage }]
                },
                {
                  key: "documentation",
                  label: "Документация",
                  icon: <InfoOutlined />,
                  items: [{ key: "documentation", label: "", component: Documentation }]
                },
              ]}
            />),
    },
];

export default InnerRoutes;