import React, { PropsWithChildren } from 'react';
import { MediaLibProvider } from './toolympus/components/medialib';
import { DictionariesProvider } from './toolympus/hooks/useDictionaries';
import { UserSettingsProvider } from './toolympus/components/Settings/UserSettingsContext';
import { DefaultUser } from './toolympus/userContext/UserContext';
import { PluggableDocumentationProvider } from './toolympus/components/Documentation';

const isAdmin = (u: DefaultUser | null | undefined) => u?.roles?.includes("admin") || u?.roles?.includes("superadmin") || false;


export const ProvidersInternal = (props: PropsWithChildren<{}>) => {
  return (
    <MediaLibProvider
      apiPath="/api/media"
      spaceId="cms"
      getFilepath={mf => `/media/${mf.space_id}/${mf.filename}`}>
      <DictionariesProvider apiPath="/api/settings/dictionaries">
        <UserSettingsProvider apiPath="/api/admin-settings" canUpdateGlobal={isAdmin} >
          <PluggableDocumentationProvider apiPath="/api/documentation" canManage={isAdmin}>
            {props.children} 
          </PluggableDocumentationProvider>
        </UserSettingsProvider>
      </DictionariesProvider>
    </MediaLibProvider>
  );
}
