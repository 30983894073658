import { useEffect, useMemo, useState } from "react";
import { apiFetch } from "../../toolympus/api/core";
import { useItemActionWithConfirmation } from "../../toolympus/api/useAction";
import { useNewItem, withActionOnSaveItem } from "../../toolympus/api/useNewItem";
import { Comment } from "../../toolympus/components/Comments";
import { Task } from "../../toolympus/components/Tasks"
import { useLoadedData } from "../../toolympus/hooks/useLoadedData"

export const useDocumentModeration = (documentId: number | string) => {
  const [doLoad, setDoLoad] = useState<boolean>(false);
  const tasks = useLoadedData<Task[]>(`/api/tasks?resolved=include&users=all&attributes.document_id=${documentId}&order-by=created_at:asc`, [], !!documentId && doLoad);
  const comments = useLoadedData<Comment[]>(`/api/comments/document_moderation/${documentId}`, [], !!documentId && doLoad);

  const newComment = useNewItem<Partial<Comment>, Comment>(
    `/api/comments/document_moderation/${documentId}`, {
      comment: "",
    }, {
      autoStartEdit: false,
    }
  );

  const [resolvingComment, setResolvingComment] = useState<Comment | null>(null);

  const doResolveComment = (c: Comment) => {
    setResolvingComment(c);
    return apiFetch<Comment>(`/api/comments/${c._id}`, "put", { is_resolved: !c.is_resolved })
      .then(c => comments.setData(x => x.map(cx => cx._id === c._id ? { ...cx, ...c } : cx )));
  }

  const resolveComment = useItemActionWithConfirmation(doResolveComment, {
    skipConfirmation: true,
  });

  useEffect(() => {
    if(!resolveComment.isRunning) {
      setResolvingComment(null);
    }
  }, [resolveComment.isRunning]);

  const [showAllComments, setShowAllcomments] = useState<boolean>(false);


  const items = useMemo(() => {
    const allItems = [
      ...tasks.data.map(t => ({ task: t, comment: null, date: t.created_at as string, comments: 0 })),
      ...comments.data.map(c => ({ task: null, comment: c, date: c.created_at })),
    ].slice().sort((a,b) => a.date < b.date ? -1 : 1);

    let currentTask: any = null;
    allItems.forEach(item => {
      if(item.task) {
        currentTask = item;
      } else if(item.comment && currentTask) {
        currentTask.comments += 1;
      }
    });
    return allItems;
  }, [tasks.data, comments.data]);

  const itemsFilter = useMemo(() => {
    const commentsCutoffTask = tasks.data.filter(t => t.is_resolved).slice(-1)[0];
    const commentFilter = (c: Comment) => commentsCutoffTask?.created_at ? c.created_at >= commentsCutoffTask.created_at : true;

    return (item: { task: Task | null, comment: Comment | null }) => item.task || showAllComments || (item.comment && commentFilter(item.comment));

  }, [tasks.data, showAllComments]);


  return {
    tasks,
    comments,
    items,
    itemsFilter,
    newComment: withActionOnSaveItem(newComment, () => comments.reload()),
    resolveComment,
    resolvingComment,
    isLoading: tasks.isLoading || comments.isLoading || newComment.isLoading,

    ensureLoaded: () => setDoLoad(true),
    reload: () => {
      if(doLoad) {
        tasks.reload();
      }
    },
    showAllComments,
    setShowAllcomments,
  }
}


export type ModerationFeedData = ReturnType<typeof useDocumentModeration>;
