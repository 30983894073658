import { useEditItem2 } from "../../api/useNewItem";
import { FileInfo, useFileList } from "../files"
import { FieldType, Schema, mergeSchema } from "../../hooks/useSchema"

export interface AttachedDocument {
  _id: string;
  filename: string;
  title: string;
  comment: string;
}

const fileInfoToDocument = (fi: FileInfo): AttachedDocument & { fileinfo: FileInfo } => ({
  _id: fi._id,
  filename: fi.filename,
  // case_record_id: +(fi.meta?.case_record_id || "-1"),
  title: fi.meta?.title || "",
  comment: fi.meta?.comment || "",
  fileinfo: fi,
});


const DocumentSchema: Schema = {
  title: { label: "Title", label_id: "attachments.fields.title" },
  comment: { label: "Comment", label_id: "attachments.fields.comment", type: FieldType.textlong },
}


export const useAttachedDocuments = (apiPath: string, parentId: number) => {
  const filelist = useFileList(`${apiPath}/${parentId}/attachment`, undefined, {
    noLoad: parentId < 0,
  });

  const editItem = useEditItem2<AttachedDocument>({
    getApiPath: doc => `${apiPath}/${parentId}/attachment/${doc._id}/info`,
  });

  return {
    ...filelist,
    schema: mergeSchema(filelist.schema, DocumentSchema),
    data: filelist.data.map(fileInfoToDocument),
    editItem: {
      ...editItem,
      save: () => editItem.save().then(x => { filelist.reload(); return x; }),
    },
  }
}

export type AttachedDocumentsData = ReturnType<typeof useAttachedDocuments>;
