import React from 'react';
import { DocumentationRoutes } from '../../../toolympus/components/Documentation';

export const Documentation = () => {
    return (
      <DocumentationRoutes
          apiPath="/api/documentation"
          rootSlug="root"
          defaultRootPageTitle="Документация"
          enableCustomFieldsBlock
          />
    );
}
