import React from 'react';
import { FormGrid } from '../../../toolympus/components/primitives';
import { FormControlsForFields } from '../../../toolympus/components/schemed';
import { DefaultModeratorIdField, useModerationSettings } from './useModerationSettings';

interface Props {
  
}

export const ModerationSettings = (props: Props) => {
  const data = useModerationSettings();

  return (
    <FormGrid>
      <FormControlsForFields
        fields={[
          [DefaultModeratorIdField, { autoComplete: true }],
        ]}
        data={data.data}
        onChange={(o,c) => data.update(c)}
        schema={data.schema}
        disabled={!data.canEdit}
        />
    </FormGrid>
  );
}
