import { useMemo, useState } from "react";
import { apiFetch } from "../../toolympus/api/core";
import { useEditItem2 } from "../../toolympus/api/useNewItem";
import { useCrudItem } from "../../toolympus/api/useSimpleCrud"
import { DocumentApiPath, Document, useDocumentSchema } from "./useDocumentsList";
import { useDocumentModeration } from "./useDocumentModeration";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData";

export const useDocumentEdit = (id: number) => {
  const data = useCrudItem<Document>(`${DocumentApiPath}/${id}`, {
    defaultValue: {
      _id: -1,
      title: "",
    }
  });

  const schema = useDocumentSchema();

  const moderation = useEditItem2<Partial<Document>>({
    startWith: { _id: id },
    getApiPath: d => `${DocumentApiPath}/moderation/${id}`,
    dontResetOnSave: true,
  });

  const moderationFeed = useDocumentModeration(id);

  const [isModerationSaving, setIsModerationSaving] = useState<boolean>(false);
  const updateDocumentModeration = async (doc: Document, newStatus: string) => {
    let d = data.data;
    if(data.hasChanges) {
      d = await data.save();
    }

    try {
      setIsModerationSaving(true);
      const updated = await apiFetch<Document>(`${DocumentApiPath}/${id}/moderation`, "put", { moderation_status: newStatus });
      data.setData({ ...d, ...updated });
      setIsModerationSaving(false);
      moderationFeed.reload();
      return updated;
    } catch(e) {
      setIsModerationSaving(false);
      throw e;
    }
  }

  const sourceTitleOptionsData = useLoadedData<Pick<Document, "source_title">[]>(`${DocumentApiPath}/source-suggestions`, []);
  const sourceTitleOptions = useMemo(() => {
    return sourceTitleOptionsData.data.map(x => x.source_title).filter(x => !!x);
  }, [sourceTitleOptionsData.data])

  return {
    ...data,
    isLoading: data.isLoading || isModerationSaving,
    schema,
    moderation: {
      ...moderation,
      save: (c?: Partial<Document>) => {
        if(data.hasChanges) {
          return data.save()
            .then(x => moderation.save(c).then(x => { data.reload(); return x; }));
        } else {
          return moderation.save(c).then(x => { data.reload(); return x; });
        }
      }
    },
    moderationFeed,

    updateDocumentModeration,
    sourceTitleOptions,
  };
}
