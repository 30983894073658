import { createTheme } from '@mui/material/styles';
import { BaseMuiThemeComponentsSettings } from './toolympus/components/primitives/BaseThemeSettings';


export const ThemeSettings = {
  colors: {
    primary: "#c43337",
    accent: "#FFCF00",
    secondary: "#FFCF00",
    accent1: "#0a81d1",
    accent2: "#eee5e9",
    accent3: "#777da7",
    text: "#040404",

    primaryShade: {
      s050: "#c4333780",
      s025: "#c4333740",
    },
    secondaryShade: {
      s050: "#FFCF0080",
      s025: "#FFCF0040",
    },

    border: "#04040440",
    
    background: {
      normal: "#ffffff",
      dark: "#111d4a",
    },

    shadow: {
      normal: "#22222280",
    },
  },

  geometry: {
    border: {
      radius: {
        panel: 24,
        controls: 12,
      },
    },

    shadow: {
      panel: "2px 2px 10px -2px",
      panel_light: "1px 1.5px 18px -4px",
      panel_glow: "0px -2px 25px -5px",
    },
  },
  typography: {
    fontFamily: "PTSans",
    fontFamilyHeaders: "PTSans",
    // fontSizes: {
    //   h2: "2rem",
    //   h3: "1.6rem",
    //   h4: "1.2rem",
    // }
  },
}

export const theme = createTheme({
    palette: {
        primary: {  
            main: ThemeSettings.colors.primary,
        },
        secondary: {
            main: ThemeSettings.colors.secondary,
        },
        text: {
          primary: ThemeSettings.colors.text,
        },
    },

    typography: {
      fontFamily: ThemeSettings.typography.fontFamily,
    },

    components: {
      ...BaseMuiThemeComponentsSettings,
    },
});

export const themeAlt = createTheme({
  palette: {
      mode: "dark",
      primary: {  
          main: ThemeSettings.colors.accent,
      },
      secondary: {
          main: ThemeSettings.colors.secondary,
      },
      text: {
        primary: ThemeSettings.colors.background.normal,
      },
      background: {
        default: ThemeSettings.colors.text,
      },
  },

  typography: {
    fontFamily: ThemeSettings.typography.fontFamily,
  },
  

  components: {
    ...BaseMuiThemeComponentsSettings,
  },
});
