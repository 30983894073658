import { FieldType, Schema } from "../../../../toolympus/hooks/useSchema";
import { TreeItemsConfigWithManage, useManageTree } from "../../../../toolympus/components/Trees";

export interface Topic {
  _id: number;
  title: string;
  parent_id?: string | null;
  sort_order: number;
  created_by?: string;
  created_at?: string;
}

export const TopicSchema: Schema = {
  _id: { label: "ID", type: FieldType.number },
  sort_order: { label: "Порядок", type: FieldType.number },
  title: { label: "Название" },
  parent_id: { label: "Группа" },
  created_by: { label: "Создана", dictionary: "Users", type: FieldType.dictionarySelect },
  created_at: { label: "Создана", type: FieldType.datetime },
}

const ApiPath = "/api/settings/topic";

export const TopicTreeConfig: TreeItemsConfigWithManage<Topic> = {
  idField: "_id",
  parentIdField: "parent_id",
  newItemDefault: { title: "" },
  sortField: "sort_order",
  textFilterBy: t => t.title,
  itemTitle: t => t.title,
}

export const useManageTopics = () => {
  const data = useManageTree<Topic>(TopicTreeConfig, ApiPath);
  

  return {
    ...data, 
  }
}
