import { Badge, BadgeProps } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { useLoadedData } from '../../hooks/useLoadedData';

interface Props extends Pick<BadgeProps, "color" | "anchorOrigin"> {
  apiPath: string;
}

export const MyTasksCountBadge = (props: PropsWithChildren<Props>) => {
  const data = useLoadedData<{ total_records: number }>(`${props.apiPath}/count`, { total_records: 0 });

  return (
    <Badge
      invisible={!data.data.total_records}
      color={props.color}
      badgeContent={data.data.total_records}
      anchorOrigin={props.anchorOrigin}>
      {props.children}
    </Badge>
  );
}
