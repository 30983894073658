import React, { ReactNode, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Document } from "./useDocumentsList";
import { Schema } from '../../toolympus/hooks/useSchema';
import { useUser } from '../../toolympus/userContext/UserContext';
import { useFormats } from '../../toolympus/components/schemed';
import { utc } from '../../toolympus/components/timezone';
import { DictionarySelectDisplay } from '../../toolympus/components/schemed/Select';
import { Menu, MenuItem } from '@mui/material';
import { StrippedIconButton } from '../../toolympus/components/primitives/StrippedButtons';
import { MoreVert } from '@mui/icons-material';

const StatusColors: Record<string, string> = {
  editing: "#0077c0",
  verifying: "#FFCF00",
  correcting: "#ed1b23",
  published: "#38b549",
}

interface DisplayProps {
  fullWidth?: boolean;
}

const Wrapper = styled.button<{ status: string } & DisplayProps>`
  padding: 8px 16px;
  background: ${props => StatusColors[props.status]};
  color: #ffffff;
  border-radius: 24px;
  border: none;
  outline: none;
  width: ${props => props.fullWidth ? "100%" : "auto"};
  cursor: pointer;

  text-align: center;
  position: relative;

  ${StrippedIconButton} {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

interface Props extends DisplayProps {
  document: Document;
  schema: Schema;
  updateStatus?: (document: Document, v: string) => Promise<Document>;
}

export const DocumentStatus = (props: Props) => {
  const { document, schema, updateStatus, ...displayProps } = props;
  const status = document.moderation_status || "editing";
  const isEditable = !!updateStatus;
  const [menuAnchor,setMenuAnchor] = useState<any>(null);


  const update = updateStatus || (() => {});

  const { user } = useUser();
  const formats = useFormats();

  const availableActions = useMemo(() => {
    const isModerator = user?.roles?.includes("moderator") || user?.roles?.includes("admin");

    switch(props.document.moderation_status) {
      case "published":
        return isModerator ? ["verifying", "published-info"] : ["published-info"];
      case "verifying":
        return isModerator ? ["published", "correcting"] : [];
      case "correcting":
        return isModerator ? ["published", "verifying"] : ["verifying"];
      default:
        return ["verifying"];
    }

  }, [props.document.moderation_status, user])

  return (<>
    <Wrapper status={status} {...displayProps} onClick={isEditable ? e => setMenuAnchor(e.target) : undefined}>
      {(props.schema.moderation_status?.valueDict || {})[status]}

      
      {isEditable && !!availableActions.length &&
        <StrippedIconButton color="inherit"><MoreVert /></StrippedIconButton>}


    </Wrapper>
    {isEditable && !!availableActions.length &&
      <Menu anchorEl={menuAnchor} open={!!menuAnchor} onClose={() => setMenuAnchor(null)}>
        {([
            ["published-info", <>Опубликован {formats.formatDatetime(utc.toLocal(document.published_at))}, <DictionarySelectDisplay row={document} field="published_by" schema={schema.published_by || { valueDict: {}}} /></>, () => {}],
            ["verifying", "Отправить на проверку", () => update(props.document, "verifying")],
            ["correcting", "Отправить на доработку", () => update(props.document, "correcting")],
            ["published", "Опубликовать", () => update(props.document, "published")],
          ] as [string,ReactNode, () => void][])
          .filter(([k]) => availableActions.includes(k as string))
          .map(([key,label,action]) => (
            <MenuItem key={key} disabled={key === "published-info"} onClick={() => { action(); setMenuAnchor(null); }}>{label}</MenuItem>
          ))}
      </Menu>}
    </>
  );
}
