import React from 'react';
import styled from '@emotion/styled';
import { TableHead, TableRow } from '@mui/material';
import { ArrowRight } from '@mui/icons-material';

export const LevelMarker = ({ level }: { level?: number }) => {
  return <>
    {!!level && <svg
      x="0px" y="0px"
      viewBox="0 0 20 15"
      style={{ width: 20, height: 15, marginLeft: (level || 0) * 20 -20 + 15 }}>
      <path style={{ fill: "none", stroke: "#00000000", strokeWidth: 1.5 }} d="M1,0V7H30" />
    </svg>}
  </>
}

export const TableHeader = styled(TableHead)`
  & th:first-child {
    width: 100%;
  }
  & th:nth-child(n+2) {
    white-space: nowrap;
  }
`;

export const CollapseButton = styled(ArrowRight)<{ isCollapsed?: boolean, isCollapsible?: boolean }>`
  cursor: pointer;
  transform: rotate(${props => props.isCollapsed ? 0 : 90}deg);
  opacity: ${props => props.isCollapsed ? 0.35 : 0.15};
  visibility: ${props => props.isCollapsible ? "visible" : "hidden"};
`;
CollapseButton.defaultProps = { role: "button" };

export const ItemRowWrapper = styled(TableRow)<{ deHighlight?: boolean }>`
  opacity: ${props => props.deHighlight ? 0.35 : 1};
`;
