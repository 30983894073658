import { useMemo } from "react";
import { useDictionaries } from "../../../../toolympus/hooks/useDictionaries";
import { Topic } from "./useManageTopics";

export const TopicsDictionaryCode = "Topics";

export const useTopicsFromDictionary = () => {
  const dictionaries = useDictionaries();
  const topicsDict = dictionaries[TopicsDictionaryCode];
  const topics: Topic[] = useMemo(() => {
    return (topicsDict.records || []).map(r => ({
      _id: +r.code,
      title: r.label,
      sort_order: r.sortorder,
      parent_id: r.extra?.parent_id,
    }));
  }, [topicsDict.records]);


  return topics;
}
