import React, { useMemo } from 'react';
import { Topic } from '../useManageTopics';
import { DialogState, Dialog, AutoexpanderDefault } from '../../../../../toolympus/components/primitives';
import { useTopicsFromDictionary } from '../topicsHelpers';
import { TreeListView } from '../../../../../toolympus/components/Trees/TreeListView';
import { useTreeView } from '../../../../../toolympus/components/Trees/useTreeView';
import { TreeItemsConfigWithManage } from '../../../../../toolympus/components/Trees';
import { StrippedButton } from '../../../../../toolympus/components/primitives/StrippedButtons';

interface Props extends Pick<DialogState, "isOpen" | "close"> {
  selectTopic: (t: Topic) => void;
}

const TreeConfig: Omit<TreeItemsConfigWithManage<Topic>, "newItemDefault"> = {
  idField: "_id",
  parentIdField: "parent_id",
  sortField: "sort_order",
  itemTitle: t => t.title,
  textFilterBy: t => t.title,
}

export const NewTopicLinkDialog = (props: Props) => {
  const topics = useTopicsFromDictionary();
  const data = useTreeView(TreeConfig, topics);

  const ActionsComponent = useMemo(() => {
    return (p: { row: Topic }) => (<>
      <StrippedButton color="primary" onClick={() => { props.selectTopic(p.row); props.close(); }}>
        выбрать
      </StrippedButton>
      </>);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectTopic])

  return (
    <Dialog
      isOpen={props.isOpen}
      close={props.close}
      maxWidth="md"
      fullWidth
      dialogTitle="Выберите тему">
      <TreeListView
        data={data}
        rowActionsComponent={ActionsComponent}
        onSubmitSingle={props.selectTopic}
        autoexpander={AutoexpanderDefault}
        />
    </Dialog>
  );
}
