import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Logo } from '.';
import { themeAlt, ThemeSettings } from '../../theme';
import { ThemeProvider } from '@mui/material/styles';

const Wrapper = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    min-height: 100svh;
    background-color: ${ThemeSettings.colors.text};
    & img {
        width: 300px;
    }
`;

export const PublicPageWrapper = (props: { children?: ReactNode }) => {
    return <Wrapper>
      <style>
        {`body { background-color: ${ThemeSettings.colors.text}}
         .MuiPaper-root { background: transparent; }`}
      </style>
      <ThemeProvider theme={themeAlt}>

        <Logo version={2} />
        {props.children}
      </ThemeProvider>
    </Wrapper>
}
