import React from 'react';
import { AutoexpanderDefault, ButtonOptionPicker, ControlCaption, SimpleList } from '../../toolympus/components/primitives';
import { useHistory } from 'react-router-dom';
import { useFields } from '../../toolympus/components/schemed';
import { SimplePowerDocViewer } from '../../toolympus/components/PowerDoc/SimplePowerDocViewer';
import { Document, useDocumentsList } from './useDocumentsList';
import { CreateDocumentDialog } from './CreateDocumentDialog';
import { PluggableDocumentationButton } from '../../toolympus/components/Documentation';
import { DocumentStatus } from './DocumentStatus';


export const DocumentsList = () => {
  const data = useDocumentsList();
  const history = useHistory();

  const fields = useFields({
    defaultFields: [
      "title",
      "document_type",
      "document_date",
      "moderation_status",
      "created_at",
    ],
    blacklist: [
      "content",
      "search_highlights",
    ],
    schema: data.schema,
    storageKey: "_iclrcmp_doclist_fields",
    extraSettings: {
      description: { tableControl: SimplePowerDocViewer },
      created_at: { utcToLocal: true, formatAsDate: true },
      updated_at: { utcToLocal: true, formatAsDate: true },
      published_at: { utcToLocal: true, formatAsDate: true },
      actualized_at: { utcToLocal: true, formatAsDate: true },
    }
  });

  return (<>
    <SimpleList
      data={{
        ...data,
        filter: data.filter.filter,
        setFilter: data.filter.setFilter,
        create: () => { data.newRecord.startEditing(); return Promise.resolve({ _id: -1 } as Document); }
      }}
      fields={[
      ]}
      fieldElement={f => {
        switch(f) {
          case "moderation_status":
            return r => <DocumentStatus document={r} schema={data.schema} fullWidth />
        }
      }}
      tabs={[
        ["Черновики", "drafts"],
        ["Опубликованные", "published"],
        ["Все", "all"],
      ]}
      schema={data.schema}
      getLinkValue={cr => `/document/${cr._id}`}
      linkFields={[ "_id", "title" ]}
      title="Документы"
      searchAuto
      searchAutoFocus
      fieldSetings={fields}
      fieldFilters={data.filtering}
      sorting={data.sorting}
      tableProps={{
        autoexpander: AutoexpanderDefault
      }}
      headerActions={<>
        <ControlCaption>Записей: {data.count.data.total_records}</ControlCaption>
      </>}
      headerViewActions={<>
        <ButtonOptionPicker
          options={[
            ["мои", "me"],
            ["все", "all"],
          ]}
          selected={data.assignedFilter.value}
          setSelected={data.assignedFilter.update}
          />
      </>}
      headerTailActions={<>
        <PluggableDocumentationButton documentationKey="documents" />
      </>}
      />

    <CreateDocumentDialog
      {...data.newRecord}
      save={() => data.newRecord.save().then(cr => { history.push(`/document/${cr._id}`); return cr; })}
      schema={data.schema}
      />
  </>);
}
