import React, { useState } from 'react';
import styled from '@emotion/styled';
import { AttachedDocumentsData, useAttachedDocuments } from './useAttachedDocuments';
import { createFileDropHandler } from '../../api/files';
import { ActionRow, Buttons, ClipboardButton, DeleteButton, Dialog, FormGrid, LoadingIndicator, OccupyFreeSpace, PseudoLink2, Tooltip } from '../primitives';
import { Button, IconButton } from '@mui/material';
import { Add, EditOutlined, GetAppOutlined, Link, SwapHoriz } from '@mui/icons-material';
import { FormControlsForFields, TableForFields } from '..//schemed';
import { FormattedMessage } from 'react-intl';


const Wrapper = styled.div<{ isDragTarget?: boolean }>`
  border: 3px dashed ${props => props.isDragTarget ? props.theme.palette.secondary.main : "transparent"};
  border-radius: 8px;
`;


interface Props {
  data: AttachedDocumentsData;
  // e.g. /api/case-record/published/document
  documentLinkPrefix: string;
}

export const AttachedDocuments = (props: Props) => {
  const { data: documents } = props;
  const [isDragTarget, setIsDragTarget] = useState<boolean>(false);

  return (<Wrapper
    isDragTarget={isDragTarget}
    onDragEnter={e => { e.preventDefault(); e.stopPropagation(); setIsDragTarget(true); }}
    onDragOver={e => { e.preventDefault(); e.stopPropagation(); setIsDragTarget(true); }}
    onDragLeave={() => setIsDragTarget(false)}
    onDrop={createFileDropHandler(f => { setIsDragTarget(false); documents.upload(f); }, true)}>

    <ActionRow>
      {documents.isLoading && <LoadingIndicator sizeVariant="s" />}
      <OccupyFreeSpace />
      <IconButton
        size="small"
        onClick={() => documents.create()}>
        <Add />
      </IconButton>
    </ActionRow>
    <TableForFields
        fields={[
          ["title"],
          ["comment"],
          ["filename"],
        ]}
        data={documents.data}
        schema={documents.schema}
        fieldElement={f => {
          switch(f) {
            case "title":
              return (row,s,orig) => <PseudoLink2 border onClick={() => documents.editItem.startEditing(row)}>{orig}</PseudoLink2>;
          }
        }}
        rowButtons={doc => <Buttons style={{ justifyContent: "end" }}>
          <Tooltip text_id="files.copyLink" withWrapper>
              <ClipboardButton value={`${props.documentLinkPrefix}/${doc._id}`} icon={<Link />} />
          </Tooltip>
          <Tooltip text_id="common.download">
              <IconButton size="small" onClick={() => documents.download(doc.fileinfo)}><GetAppOutlined /></IconButton>
          </Tooltip>
          <Tooltip text_id="common.edit">
              <IconButton size="small" onClick={() => documents.editItem.startEditing(doc)}><EditOutlined /></IconButton>
          </Tooltip>
          <Tooltip text_id="common.replace">
              <IconButton size="small" onClick={() => documents.replace(doc.fileinfo)}><SwapHoriz /></IconButton>
          </Tooltip>
          <Tooltip text_id="common.delete" withWrapper>
              <DeleteButton
                title={<FormattedMessage id="files.removeConfirmation" />}
                remove={() => documents.remove(doc.fileinfo)}
                size="small"
                preventGoBack />
          </Tooltip>
        </Buttons>}
        />

      <Dialog
        dialogTitle="File"
        isOpen={documents.editItem.isEditing}
        close={() => documents.editItem.cancel()}
        noFullscreen
        noSubmitOnEnter
        actions={<>
          <Button color="primary" variant="contained" onClick={() => documents.editItem.save()}><FormattedMessage id="common.save" /></Button>
          <Button onClick={() => documents.editItem.cancel()}><FormattedMessage id="common.close" /></Button>
        </>}
      >
        {!!documents.editItem.item && <FormGrid columns="1fr" noMargin>
          <FormControlsForFields
            data={documents.editItem.item}
            schema={documents.schema}
            fields={[
              ["title"],
              ["comment", { autoRows: true }],
            ]}
            onChange={(o,c) => documents.editItem.update(c)}
            />
        </FormGrid>}
      </Dialog>
  </Wrapper>);
}

interface BoundProps {
  apiPath: string;
  parentId: number;
  documentLinkPrefix: string;
}

export const AttachedDocumentsBound = (props: BoundProps) => {
  const data = useAttachedDocuments(props.apiPath, props.parentId);

  return <AttachedDocuments
    data={data}
    documentLinkPrefix={props.documentLinkPrefix}
    />
}
