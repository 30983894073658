import React, { ReactNode } from 'react';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';
import { LocalizationProvider as DatesLocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

import LocalizationProvider from './toolympus/components/localization/LocalizationProvider';
import { FormatsProvider } from './toolympus/components/schemed';

import { theme } from './theme';
import { ConfirmationDialogProvider } from './toolympus/components/primitives';
import { IclrcMpMessages } from './iclrcmpMessages';

interface Props {
    children?: ReactNode;
}

export const Providers = (props: Props) => {
    return (
        <ThemeProvider theme={theme}>
            <StylesProvider injectFirst >
                <LocalizationProvider locale="ru" defaultLocale="ru" messages={IclrcMpMessages}>
                    <FormatsProvider formats={{ date: "DD.MM.YYYY" }}>
                      <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                        <DatesLocalizationProvider dateAdapter={AdapterMoment}>
                          <ConfirmationDialogProvider>
                            {props.children}
                          </ConfirmationDialogProvider>
                        </DatesLocalizationProvider>
                      </SnackbarProvider>
                    </FormatsProvider>
                </LocalizationProvider>
            </StylesProvider>
        </ThemeProvider>
    );
}
