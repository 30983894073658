import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Buttons } from './ActionRow';
import { Button, Typography } from '@mui/material';
import { LoadingIndicator } from './LoadingIndicator';
import { DictionarySelectDisplay } from '../schemed/Select';
import { useFormats } from '../schemed';
import { utc } from '../timezone';


const StatusWrapper = styled.div`
  display: flex;
  flex-flow: column;

  & .checked-info {
    line-height: 1.1;
  }

  & .checked-at {
    white-space: nowrap;
  }
`;

const Wrapper = styled(Buttons)`

`;


interface Props {
  isChecked?: boolean;
  isCheckedBy?: string;
  isCheckedAt?: string;
  isLoading?: boolean;
  usersDictionaryName?: string;

  checkedLabel: ReactNode;
  uncheckedLabel: ReactNode;
  checkLabel: ReactNode;
  uncheckLabel: ReactNode;

  canControl?: boolean;
  doCheck: () => void;
  doUncheck: () => void;

  wrapperStyle?: React.CSSProperties;
}

export const UserCheckStatus = (props: Props) => {
  const { formatDatetime } = useFormats();

  return (
    <Wrapper className="user-check-status" style={props.wrapperStyle}>
      {!!props.isChecked && (
        <StatusWrapper>
          <Typography className="label checked-label">{props.checkedLabel}</Typography>

          <Typography variant="caption" color="textSecondary" component="p" className="checked-info">
            <span className="checked-at">{formatDatetime(utc.toLocal(props.isCheckedAt))}</span>, <span className="checked-by">
              {props.usersDictionaryName
                ? <DictionarySelectDisplay row={props} field="isCheckedBy" schema={{ dictionary: props.usersDictionaryName}} />
                : props.isCheckedBy}
            </span>
          </Typography>
          
        </StatusWrapper>
      )}
      {!props.isChecked && (
        <>
          <Typography className="label unchecked-label">{props.uncheckedLabel}</Typography>
        </>
      )}
      {props.canControl && (
        props.isChecked
          ? <Button
              startIcon={props.isLoading ? <LoadingIndicator color="inherit" sizeVariant="s" /> : undefined}
              size="small"
              onClick={() => props.doUncheck()}>
                {props.uncheckLabel}
            </Button>
          : <Button
              startIcon={props.isLoading ? <LoadingIndicator color="inherit" sizeVariant="s" /> : undefined}
              size="small"
              color="primary"
              onClick={() => props.doCheck()}>
                {props.checkLabel}
            </Button>
      )}
    </Wrapper>
  );
}
